import $axios from '@/utils/request'

// 查询全部校区
export function getListListenStu (data) {
  const url = '/user/listByLimitEmployCampus'
  return $axios.fPost(url, data)
}

export function addStudent (data) {
  const url = '/user/addStudent'
  return $axios.fPost(url, data)
}
